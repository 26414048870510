import React from "react"
import Slider from "react-slick"
import { Row, Col } from "react-bootstrap"
import Axios from "axios"
import { REQUEST_URL, PATH_URL } from "../../../config"

class Character extends React.Component {
  state = {
    data: [],
  }

  componentDidMount = () => {
    Axios.post(REQUEST_URL + "/fifthcharacter/", {}).then(response => {
      if (response.data.status) {
        this.setState({
          data: response.data.data,
        })
        this.props.ChkCharacter(true, response.data.data)
      }
    })
  }

  render() {
    var settings = {
      // dots: true,
      // infinite: true,
      speed: 500,
      slidesToShow: 10,
      slidesToScroll: 10,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
      // nextArrow: <SampleNextArrow />,
      // prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            infinite: true,
          },
        },
      ],
    }
    return (
      <div
        style={{
          backgroundColor: "black",
          overflowX: "hidden",
        }}
      >
        <Row>
          {this.props.Show ? (
            <Col md="12" style={{ marginTop: "1%" }}>
              <Slider
                {...settings}
                style={{ marginLeft: "5%", marginRight: "5%" }}
              >
                {this.props.Data.map((item, index) => {
                  return (
                    <div key={index}>
                      <div
                        style={{
                          position: "inline-flex",
                          marginLeft: "-30%",
                        }}
                      >
                        <img
                          src={PATH_URL + "character/" + item.nameimg}
                          width="100%"
                          className="characterimg"
                          // onMouseOver={e =>
                          //   (e.currentTarget.src = require("../../../img/character/" +
                          //     this.state.data[index].imageM))
                          // }
                          // onMouseOut={e =>
                          //   (e.currentTarget.src = require("../../../img/character/" +
                          //     this.state.data[index].imageS))
                          // }
                        />
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </div>
    )
  }
}

export default Character
