import React, { Component } from "react"
import { Link, navigate } from "gatsby"
import { REQUEST_URL, PATH_URL } from "../../../config"
import { Card, Row, Col, Button, Spinner } from "react-bootstrap"
import classnames from "classnames"
import axios from "axios"

class Details extends Component {
  constructor(props) {
    super(props)
    this.state = {
      datavar: [],
      datavarcolor: [],
      dataother: [],
      Brand: "",
      Colour: "",
      Material: "",
      Description: "",
      X: 0,
      M: null,
      Mz: null,
      activeColor: false,
      ColorTxt: null,
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.ID !== this.props.ID) {
      this.detailData()
    }
  }

  componentDidMount = () => {
    this.detailData()
  }

  detailData = () => {
    axios
      .post(REQUEST_URL + "/itemdetails/", {
        ID: this.props.ID,
      })
      .then(response => {
        console.log("attribute_value1",response.data.dataatt)
        if (response.data.status) {
          const A =
            ((parseInt(response.data.dataatt[0].original_price) -
              parseInt(response.data.dataatt[0].price)) /
              parseInt(response.data.dataatt[0].original_price)) *
            100
          this.setState({
            datavar: [response.data.datavar],
            datavarcolor: response.data.datavar,
            dataother: response.data.dataother,
            Rating: response.data.dataatt[0].rating_star,
            Like: response.data.dataatt[0].like,
            Sales: response.data.dataatt[0].sales,
            OriginalPrice: response.data.dataatt[0].original_price,
            Price: response.data.dataatt[0].price,
            Stock: response.data.dataatt[0].stock,
            StockAll: response.data.dataatt[0].stock,
            Brand: response.data.dataatt[0].attribute_value,
            Colour: response.data.dataatt[1].attribute_value,
            Material: response.data.dataatt[2].attribute_value,
            Description: response.data.dataatt[0].description,
            Discount: A,
            ID: response.data.dataatt[0].item_id,
          })
        }
      })
  }

  onClickActive = (index, Type, txt) => e => {
    e.preventDefault()
    if (Type === "Color") {
      if (this.state.activeColor && this.state.Mz === index) {
        this.setState({
          M: null,
          Mz: null,
          activeColor: false,
          ColorTxt: null,
        })
      } else {
        this.setState({
          M: index,
          Mz: index,
          activeColor: true,
          ColorTxt: txt,
        })
      }
    }
  }

  render() {
    return (
      <div
        className=""
        style={{
          width: "100%",
        }}
      >
        {this.state.datavar.length === 0 ? (
          <Row style={{ marginBottom: "5%" }}>
            <Col md="12" xs="12" sm="12">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <Spinner animation="grow" variant="primary" />
                <Spinner animation="grow" variant="success" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="warning" />
                <Spinner animation="grow" variant="info" />
                <Spinner animation="grow" variant="secondary" />
                <Spinner animation="grow" variant="dark" />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1 style={{ fontFamily: "psl_textregular" }}>
                  กำลังโหลดข้อมูล กรุณารอสักครู่
                </h1>
              </div>
            </Col>
          </Row>
        ) : (
          <div
            className="container-fluid"
            style={{ width: "100%", marginTop: "2%", marginBottom: "3%" }}
          >
            <Row>
              <Col md="5">
                <Col md="12">
                  {this.state.StockAll === 0 ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "30%",
                        left: "30%",
                        background: "black",
                        height: "35%",
                        width: "35%",
                        borderRadius: "50%",
                        opacity: "0.75",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "35%",
                          fontSize: "24px",
                          color: "white",
                        }}
                      >
                        สินค้าหมด
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.datavar.map((item, index) => {
                    return (
                      <img
                        src={
                          this.state.M !== null
                            ? item[this.state.M].images
                            : item[this.state.X].images
                        }
                        width="100%"
                        key={index}
                      />
                    )
                  })}
                </Col>
              </Col>
              <Col md="7">
                <label style={{ fontSize: "36px", color: "black" }}>
                  {this.state.Name}
                </label>
                <label style={{ color: "red", fontSize: "22px" }}>
                  {parseInt(this.state.Rating).toFixed(2)} Rating
                  <span style={{ color: "black", fontSize: "22px" }}>
                    {" "}
                    | {this.state.Like} ถูกใจ |{" "}
                  </span>{" "}
                  <span style={{ color: "black", fontSize: "22px" }}>
                    {this.state.Sales} ขายแล้ว
                  </span>
                </label>
                {this.state.OriginalPrice === this.state.Price ? (
                  <div style={{ marginTop: "2%" }}>
                    <label
                      style={{
                        color: "red",
                        fontSize: "42px",
                        marginLeft: "2%",
                      }}
                    >
                      ฿{this.state.Price}
                    </label>
                  </div>
                ) : (
                  <div>
                    <label>
                      <span
                        style={{
                          color: "grey",
                          fontSize: "26px",
                          textDecoration: "line-through",
                          marginLeft: "5%",
                        }}
                      >
                        ฿{this.state.OriginalPrice}
                      </span>
                      <span
                        style={{
                          color: "red",
                          fontSize: "42px",
                          marginLeft: "2%",
                        }}
                      >
                        ฿{this.state.Price}
                      </span>
                    </label>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        background: "red",
                        width: "90px",
                        height: "30px",
                        marginLeft: "8%",
                      }}
                    >
                      <label
                        style={{
                          color: "white",
                          fontSize: "20px",
                          marginLeft: "2%",
                        }}
                      >
                        {parseInt(this.state.Discount).toFixed(0)}% ส่วนลด
                      </label>
                    </div>
                  </div>
                )}
                <div style={{ marginTop: "2%" }}>
                  <label style={{ marginRight: "5%", fontSize: "30px" }}>
                    สีทั้งหมดที่มีเฉพาะรุ่น {this.state.SKU}
                  </label>
                </div>
                <Row>
                  {this.state.datavarcolor.map((item, index) => {
                    return (
                      <Col md="3" xs="4" style={{ marginTop: "1%" }}>
                        <Button
                          className={classnames({
                            active:
                              this.state.activeColor && this.state.Mz === index,
                          })}
                          variant="outline-dark"
                          onClick={this.onClickActive(
                            index,
                            "Color",
                            item.color
                          )}
                          onMouseOver={e =>
                            this.setState({ X: index, M: null })
                          }
                          onMouseOut={e =>
                            this.setState({ X: index, M: this.state.Mz })
                          }
                          style={{
                            width: "100%",
                            fontSize: "28px",
                          }}
                        >
                          {item.color}
                        </Button>
                      </Col>
                    )
                  })}
                </Row>
                <Row>
                  <Col
                    md="6"
                    xs="12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "3%",
                    }}
                  >
                    <a
                      onClick={() =>
                        window.open(
                          "https://shopee.co.th/product/84387866/" +
                            this.state.ID
                        )
                      }
                    >
                      <img
                        src={
                          "https://cf.shopee.co.th/file/15673fb21c9b73f7affe75dab165ff25"
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </a>
                  </Col>
                  <Col
                    md="6"
                    xs="12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "3%",
                    }}
                  >
                    {/* <a
                    onClick={() =>
                      window.open(
                        "https://shopee.co.th/product/84387866/" + this.state.ID
                      )
                    }
                  >
                    <img
                      src={
                        "https://cf.shopee.co.th/file/15673fb21c9b73f7affe75dab165ff25"
                      }
                      style={{ cursor: "pointer" }}
                    />
                  </a> */}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md="9">
                <label
                  style={{
                    fontSize: "32px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  ข้อมูลจำเพาะของสินค้า
                </label>
                <label
                  style={{
                    fontSize: "28px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  {this.state.Brand === ""
                    ? ""
                    : "ยี่ห้อ : " + this.state.Brand}
                </label>
                {this.state.Brand !== "" ? "" : ""}
                <label
                  style={{
                    fontSize: "28px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  {this.state.Colour === "" ? "" : "สี : " + this.state.Colour}
                </label>
                {this.state.Colour !== "" ? "" : ""}
                <label
                  style={{
                    fontSize: "28px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  {this.state.Material === ""
                    ? ""
                    : "วัสดุ : " + this.state.Material}
                </label>
                {this.state.Material !== "" ? "" : ""}
                <label
                  style={{
                    fontSize: "30px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  รายละเอียดสินค้า
                </label>
                <label style={{ fontSize: "20px", color: "black" }}>
                  {this.state.Description}
                </label>
              </Col>
              <Col md="3"></Col>
            </Row>
            <Row>
              <Col
                md="6"
                xs="8"
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  marginTop: "2%",
                  marginBottom: "3%",
                  color: "black",
                }}
              >
                สินค้าอื่น ๆ ที่คุณอาจสนใจ
              </Col>
            </Row>
            <Row>
              {this.state.dataother.map(item => {
                return (
                  <Col
                    md="2"
                    xs="4"
                    style={{
                      display: "flex",
                      alignItems: "stretch",
                      marginTop: "1%",
                    }}
                  >
                    <Link
                      to={`/details/?${item.name + "+" + item.item_id}`}
                      style={{ color: "black" }}
                      // onClick={() =>
                      //   this.setState({
                      //     ColorSize: [],
                      //     SizeColor: [],
                      //   })
                      // }
                    >
                      <img
                        src={item.images}
                        width="100%"
                        className="otherimg"
                      />
                      <label style={{ color: "red", fontSize: "26px" }}>
                        ฿{item.price}
                      </label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "stretch",
                          height: "60px",
                        }}
                      >
                        <label style={{ fontSize: "16px" }}>{item.name}</label>
                      </div>
                      <hr></hr>
                    </Link>
                  </Col>
                )
              })}
            </Row>
          </div>
        )}
      </div>
    )
  }
}

export default Details
