import React from "react"
import { Row, Col, Collapse, Card, Button, Spinner } from "react-bootstrap"
import {
  FaCaretDown,
  FaCaretUp,
  FaExpandArrowsAlt,
  FaCheckSquare,
  FaEye,
  FaRegHeart,
} from "react-icons/fa"
import axios from "axios"
import { REQUEST_URL, PATH_URL } from "../../../config"

class BestSellerPage extends React.Component {
  state = {
    data: [],
  }

  componentDidMount = () => {
    this.loadData()
  }

  loadData = () => {
    axios.post(REQUEST_URL + "/itembestseller/", {}).then(response => {
      if (response.data.status) {
        this.setState({
          data: response.data.data,
        })
      }
    })
  }

  render() {
    return (
      <div style={{ marginBottom: "5%", marginTop: "2%" }}>
        {this.state.data.length === 0 ? (
          <Row>
            <Col md="12" xs="12" sm="12">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <Spinner animation="grow" variant="primary" />
                <Spinner animation="grow" variant="success" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="warning" />
                <Spinner animation="grow" variant="info" />
                <Spinner animation="grow" variant="secondary" />
                <Spinner animation="grow" variant="dark" />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1 style={{ fontFamily: "psl_textregular" }}>
                  กำลังโหลดข้อมูล กรุณารอสักครู่
                </h1>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Row>
              <Col md="12">
                <label style={{ fontSize: "42px", color: "black" }}>
                  สินค้าขายดี
                </label>
              </Col>
            </Row>
            <Row>
              {this.state.data.map((item, index) => {
                return (
                  <Col md="3" key={index}>
                    <Card
                      // border="secondary"
                      style={{
                        marginTop: "30px",
                        cursor: "pointer",
                        border: "0px",
                      }}
                    >
                      <a
                        onClick={() =>
                          window.open(
                            `/details/?${item.name + "+" + item.item_id}`
                          )
                        }
                      >
                        <Card.Img
                          variant="top"
                          src={item.images}
                          width="100%"
                          // className="productimg"
                          onMouseOver={
                            item.images1
                              ? e => (e.currentTarget.src = item.images1)
                              : e => (e.currentTarget.src = item.images)
                          }
                          onMouseOut={e => (e.currentTarget.src = item.images)}
                        />
                        <Card.Header style={{ backgroundColor: "white" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label
                              style={{
                                fontSize: "18px",
                                color: "black",
                              }}
                            >
                              {item.item_sku}
                            </label>
                            <label
                              style={{
                                fontSize: "18px",
                                color: "black",
                              }}
                            >
                              {item.likes === 0 ? 1 : item.likes} 
                              <FaRegHeart className="itemlikes" />
                            </label>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label
                              style={{
                                fontSize: "18px",
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              <span style={{ fontWeight: "normal" }}>฿</span>
                              {item.price}
                            </label>
                            <label
                              style={{
                                fontSize: "18px",
                                color: "black",
                              }}
                            >
                              {item.views === 0 ? 99 : item.views} 
                              <FaEye className="itemviews" />
                            </label>
                          </div>
                        </Card.Header>
                      </a>
                    </Card>
                  </Col>
                )
              })}
            </Row>
          </Row>
        )}
      </div>
    )
  }
}

export default BestSellerPage
