import React from "react"
import { Link } from "gatsby"
import { Row, Col } from "react-bootstrap"
// import loading from "../../../img/newproduct/loadingstyle.gif"
// import men from "../../../img/newproduct/w1-01.png"
// import women from "../../../img/newproduct/w2-01.png"
// import kids from "../../../img/newproduct/w3-01.png"
// import { FaAngleRight, FaChevronRight } from "react-icons/fa"
import Axios from "axios"
import { REQUEST_URL, PATH_URL } from "../../../config"

class NewProduct extends React.Component {
  state = {
    nameimg: "",
  }

  componentDidMount = async () => {
    await Axios.post(REQUEST_URL + "/secstyle/", {}).then(response => {
      if (response.data.status) {
        this.setState({
          nameimg: response.data.data[0].nameimg,
        })
        this.props.ChkStyle(true, response.data.data[0].nameimg)
      }
    })
  }

  render() {
    return (
      <div>
        <div style={{ width: "100%", marginTop: "1%" }}>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: "0px",
              marginRight: "0px",
            }}
          >
            {this.props.Show ? (
              <Col md="12" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                <img
                  src={PATH_URL + "style/" + this.props.Data}
                  className="NewProduct"
                />
                <Link to="/men">
                  <div className="bannernewblockmen">
                    <label className="bannertxtmen">
                      รองเท้า ผู้ชาย <span className="looksorn">﹥</span>
                    </label>
                  </div>
                  <div className="bannernewbordermen"></div>
                </Link>
                <Link to="/women">
                  <div className="bannernewblockwomen">
                    <label className="bannertxtwomen">
                      รองเท้า ผู้หญิง <span className="looksorn">﹥</span>
                    </label>
                  </div>
                  <div className="bannernewborderwomen"></div>
                </Link>
                <Link to="/kids">
                  <div className="bannernewblockkids">
                    <label className="bannertxtkids">
                      รองเท้า เด็ก <span className="looksorn">﹥</span>
                    </label>
                  </div>
                  <div className="bannernewborderkids"></div>
                </Link>
              </Col>
            ) : (
              ""
            )}
            {/* <Col md="12">
              <img
                src={men}
                style={{
                  zIndex: "1",
                  position: "relative",
                  top: "-370%",
                  width: "15%",
                  left: "4%",
                }}
              />
            </Col>
            <Col md="12">
              <img
                src={women}
                style={{
                  zIndex: "1",
                  position: "relative",
                  top: "-400%",
                  width: "20%",
                  left: "4%",
                }}
              />
            </Col>
            <Col md="12">
              <img
                src={kids}
                style={{
                  zIndex: "1",
                  position: "relative",
                  top: "-430%",
                  width: "15%",
                  left: "4%",
                }}
              />
            </Col> */}
          </Row>
          {/* <Row style={{ position: "relative" , top:"-100px" , zIndex:1100 }}>
            <Col md="12">
              <Button color="primary">SHOP MEN</Button>
            </Col>
          </Row> */}
        </div>
      </div>
    )
  }
}

export default NewProduct
