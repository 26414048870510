import React from "react"
import { Modal, Button, ProgressBar } from "react-bootstrap"
import ReactPlayer from "react-player"
import vd1 from "../../../../img/carousel/Comp-1.mp4"

class ModalIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = { seconds: 25, maxvideo: 25 }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.seconds !== this.state.seconds) {
      if (this.state.seconds === 0) {
        this.props.set(false)
      }
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => this.progressTime(), 1000)
  }

  progressTime = () => {
    this.setState(prevState => ({
      seconds: prevState.seconds - 1,
    }))
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  modalClose = bool => e => {
    this.props.set(bool)
  }

  render() {
    return (
      <div style={{ marginTop: "100px" }}>
        <Modal
          size="lg"
          show={this.props.Modal}
          onHide={this.modalClose(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <ReactPlayer
              url={vd1}
              autoPlay
              playing={true}
              loop={true}
              width="100%"
              height="100%"
              volume={0}
            />
            <ProgressBar
              animated
              now={this.state.seconds}
              max={this.state.maxvideo}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ fontSize: "22px" }}
              onClick={this.modalClose(false)}
              variant="danger"
            >
              ปิดโฆษณา
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default ModalIndex
