import React from "react"
import { Navbar, Nav } from "react-bootstrap"
import { Link } from "gatsby"
import LogoAdda from "../../../img/logo.png"

class NavNav extends React.Component {
  render() {
    return (
      <div className="sticky">
        <Navbar
          collapseOnSelect
          expand="lg"
          // variant="dark"
          style={{
            position: "sticky",
            width: "100%",
            background: "black",            
            // height: "53px",
            zIndex: "1100",
            top: "0px",
            boxShadow: "0 2px 2px -2px rgba(0,0,0,.9)",            
            // background: "transparent",
          }}
        >
          <Navbar.Brand style={{ marginLeft: "10%" }}>
            <Link to="/">
              <img
                src={LogoAdda}
                alt="Logo"
                width="80%"
                style={{ marginBottom: "0px" }}
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle style={{ backgroundColor: "white" }} />
          <Navbar.Collapse
            style={{ justifyContent: "flex-end", marginRight: "7%" }}
          >
            <Nav.Item
              style={{
                fontFamily: "PSL-Kanda",
                color: "white",
                fontSize: "20px",
                marginRight: "20px",
              }}
            >
              <Link style={{ color: "white" }} to="/">
                หน้าหลัก
              </Link>
            </Nav.Item>
            <Nav.Item
              style={{
                fontFamily: "PSL-Kanda",
                color: "white",
                fontSize: "20px",
                marginRight: "20px",
              }}
            >
              <Link style={{ color: "white" }} to="/men">
                รองเท้าผู้ชาย
              </Link>
            </Nav.Item>
            <Nav.Item
              style={{
                fontFamily: "PSL-Kanda",
                color: "white",
                fontSize: "20px",
                marginRight: "20px",
              }}
            >
              <Link style={{ color: "white" }} to="/women">
                รองเท้าผู้หญิง
              </Link>
            </Nav.Item>
            <Nav.Item
              style={{
                fontFamily: "PSL-Kanda",
                color: "white",
                fontSize: "20px",
                marginRight: "20px",
              }}
            >
              <Link style={{ color: "white" }} to="/kids">
                รองเท้าเด็ก
              </Link>
            </Nav.Item>
            <Nav.Item
              style={{
                fontFamily: "PSL-Kanda",
                color: "white",
                fontSize: "20px",
                marginRight: "20px",
              }}
            >
              <Link style={{ color: "white" }} to="/contact">
                ติดต่อเรา
              </Link>
            </Nav.Item>
            <Nav.Item
              style={{
                fontFamily: "PSL-Kanda",
                color: "white",
                fontSize: "20px",
                marginRight: "20px",
              }}
            >
              {/* <Link style={{ color: "white" }} to="/about-us">
                About us
              </Link> */}
            </Nav.Item>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
}

export default NavNav
