import React, { Component } from "react"
import { Link } from "gatsby"
// import { REQUEST_URL, PATH_URL } from "../../../config"
// import { Card, Row, Col, Button } from "react-bootstrap"
// import topone from "../../../img/newnbest/2DEN-TOP1.jpg"
// import toptwo from "../../../img/newnbest/2DEN-TOP2.jpg"
// import topthree from "../../../img/newnbest/2DEN-TOP3.jpg"
import Axios from "axios"
import { REQUEST_URL, PATH_URL } from "../../../config"

class BannerProperty extends Component {
  state = {
    data: [],
  }

  componentDidMount = () => {
    Axios.post(REQUEST_URL + "/propertypage/", {}).then(response => {
      if (response.data.status) {
        this.setState({
          data: response.data.data,
        })
      }
    })
  }

  render() {
    return (
      <div
        className=""
        style={{
          width: "100%",
          marginTop: "2%",
          marginBottom: "2%",
        }}
      >
        {this.state.data.map((item, index) => {
          return (
            <Link to="/2density">
              <img
                src={PATH_URL + "property/" + item.nameimg}
                width="100%"
                style={{ marginBottom: "1rem", cursor: "pointer" }}
                key={index}
              />
            </Link>
          )
        })}
      </div>
    )
  }
}

export default BannerProperty
