import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "../../css/fonts.css"
import "../../css/main.css"
import "../../css/util.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import NavNavTop from "./Component/NavbarTop"
import NavNav from "./Component/Navbar"
import TwoDensity from "./Component/TwoDensity"
import Footer from "./Component/Footer"
import Helmet from "react-helmet"

class density extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>บริษัท แอ๊ดด้า ฟุตแวร์ (ไทยแลนด์) จำกัด</title>
        </Helmet>
        <NavNavTop />
        <NavNav />
        <div className="container">
          <TwoDensity />
        </div>
        <Footer />
      </div>
    )
  }
}

export default density
