import React, { Component } from "react"
import { Row, Col, Image, Card } from "react-bootstrap"
import logo from "../../../img/logo.png"
import img1 from "../../../img/shareholder/1.jpg"
import img2 from "../../../img/shareholder/2.jpg"
import img3 from "../../../img/shareholder/3.jpg"
import img4 from "../../../img/shareholder/4.jpg"
import img5 from "../../../img/shareholder/5.jpg"
import img6 from "../../../img/shareholder/6.jpg"
import img7 from "../../../img/shareholder/7.jpg"
import img8 from "../../../img/shareholder/8.jpg"
import img9 from "../../../img/shareholder/9.jpg"

const data = [
  {
    image: img1,
    name: "Prasit Pruksapornpong",
    role: "Managing Director / Shareholder",
    role1: "",
  },
  {
    image: img2,
    name: "Kanjana Pruksapornpong",
    role: "Shareholder",
    role1: " ",
  },
  {
    image: img3,
    name: "Kavi Prucksapornpong",
    role: "Deputy Managing Director / Shareholder",
    role1: " ",
  },
  {
    image: img4,
    name: "Isaree Pruksapornpong",
    role: "Export Manager / Shareholder",
    role1: " ",
  },
  {
    image: img5,
    name: "Lattapon Pruksapornpong",
    role: "Production Management Director",
    role1: "QC Engineer / Shareholder",
  },
  {
    image: img6,
    name: "Chanasorn Pruksapornpong",
    role: "Administrative Director / Shareholder",
    role1: " ",
  },
  {
    image: img7,
    name: "Phunnavich Prucksapornpong",
    role: "Shareholder",
    role1: " ",
  },
  {
    image: img8,
    name: "Sethakorn Chatmaitri",
    role: "Sale & Marketing Director / Shareholder",
    role1: " ",
  },
  {
    image: img9,
    name: "Pumjai Chatmaitri",
    role: "Information Technology & Logistics Director",
    role1: "Shareholder",
  },
]

const texts = [
  {
    title: "OUR STORY",
    text: [
      "Established in 1986 with 100% Thai ownership, the brand ADDA is a footwear manufacturer and distributor based in Bangkok, Thailand. Previously an ODM company, we have the experience and expertise in offering international design and export quality products to various customers across the globe.",
    ],
  },
  {
    title: "MISSION&VISION",
    text: [
      "We strive to create a sustainable system of ongoing improvement in terms of product developments, distribution, and quality control through the efficient use of resources. Innovation is ingrained into every aspect of our culture whether it’s product design and development, production technology, and human resource. Through this, we believe we can bring satisfaction, pride, success and happiness to all stakeholders.",
    ],
  },
  {
    title: "EXPERTISE",
    text: [
      "Situated in 6 acres of land in the Bangkok suburb, we have a daily production capacity of 130,000 pairs of footwear. With a workforce of 3,300 people, we are able to produce a diverse range of products including slippers, sandals, flip flops, sports and canvas shoes, and student shoes. The techniques employed include EVA molding, EVA dual density molding, PVC and air blow molding, PU molding, TPR pouring, and much more.",
    ],
  },
  {
    title: "CHANNELS",
    text: [
      "The brand ADDA is well known throughout Thailand with over 900 retail and wholesale partners. Our products can also be found in malls and hypermarkets such as Central, The Mall, Robinson, Big C, and Tesco Lotus as well as online platforms Shopee and Lazada.",
      "Our products can also be found in the following countries: India, Japan, Taiwan, Hong Kong, Saudi Arabia, UAE, Cambodia, Laos, Myanmar, Vietnam, Malaysia, Philippines, Brunei, Sri Lanka, Pakistan, Bangladesh, Georgia, Israel, Algeria, and Mauritania.",
    ],
  },
  {
    title: "CERTIFICATIONS",
    text: [
      "The brand ADDA has been certified and approved by the Thai Department of Internal Trade Promotion and Ministry of Commerce to use the ‘Thailand Trust Mark’ (T Mark).",
      "The brand ADDA has been audited and certified to be in accordance to the standards of OHSAS 18001:2007 and ISO 9001:2015.",
      "The brand ADDA has passed the ILS Social Compliance Audit",
    ],
  },
  {
    title: "COMMUNITY INVOLVEMENT",
    text: [
      "At ADDA, we identify ourselves with the local community and are constantly involved. Our ADDA Design Contest offers campus design workshop and contest in universities nationwide. ADDA Flood Relief projects are also a part of our effort in showing solidarity in times of hardship. Other projects include ADDA Share with Youngsters and ADDA Blood Donation to the Thai Red Cross.",
    ],
  },
]

class AboutUs extends React.Component {
  state = {
    data: [],
  }

  render() {
    return (
      <div
        style={{
          marginBottom: "5%",
          marginTop: "2%",
          marginLeft: "50px",
          marginRight: "50px",
          fontSize: "18px",
          fontWeight: "bold",
          color: "black",
          textAlign: "center",
          // paddingLeft:'50px',
          // paddingRight:'50px'
        }}
      >
        <Image src={logo} />
        {/* <h2>OUR COMPLETE SHAREHOLDERS LIST</h2> */}
        <h1>OUR COMPLETE SHAREHOLDERS LIST</h1>
        <h3>100% THAI FAMILY OWNED COMPANY</h3>
        <Row>
          {data.map((item, idx) => (
            <Col
              sm={6}
              md={6}
              xl={4}
              style={{ textAlign: "center", marginBottom: "10px" }}
            >
              <Card
                style={{
                  height: "100%",
                  alignItems: "center",
                  // aspectRatio: "1/1.35",
                  flexShrink: "1",
                  padding: "1.5em .5em .5em",
                  marginTop: "10px",
                  boxShadow: "0 5px 10px rgba(0,0,0,.2)",
                }}
                key={idx.toString()}
              >
                <Image
                  className="ratio ratio1x1"
                  src={item.image}
                  sizes="200"
                  style={{
                    marginTop: "20px",
                    // width: "300px",
                    // height: "300px",
                    overflow: "hidden",
                    aspectRatio: "1/1",
                    padding: "5%",
                    // marginLeft:'10%',
                    // marginRight:'10%'
                  }}
                  roundedCircle
                />
                <h5
                  style={{
                    fontWeight: 700,
                    fontSize: "1.5em",
                  }}
                >
                  {item.name}
                </h5>
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: "1.5em",
                  }}
                >
                  {item.role}
                </span>
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: "1.5em",
                  }}
                >
                  {item.role1}
                </span>
                <p
                  style={{
                    marginTop: "auto",
                    fontWeight: 700,
                    fontSize: "1.1em",
                  }}
                >
                  Nationality THAI
                </p>
              </Card>
            </Col>
          ))}
        </Row>
        <div
          style={{
            marginTop: "5em",
            textAlign: "left",
          }}
        >
          {texts.map(item => (
            <>
              <div
                style={{
                  textAlign: "center",
                  marginBottom:'20px'
                }}
              >
                <h3
                  style={{
                    marginBottom: "3px",
                    // alignItems:'center'
                  }}
                >
                  {item.title}
                </h3>
              </div>
              {item.text.map((text, idx) => (
                <p
                  style={{
                    fontSize: "1.5em",
                    textAlign:'justify'
                  }}
                >
                  &nbsp;&nbsp;
                  {text}
                </p>
              ))}
              <br />
              <br />
            </>
          ))}
        </div>
      </div>
    )
  }
}

export default AboutUs
