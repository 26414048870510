import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "../../css/Style.css"
import "../../css/fonts.css"
import "../../css/util.css"
import "../../css/main.css"
import "../../css/loader.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import NavNavTop from "./Component/NavbarTop"
import NavNav from "./Component/Navbar"
import CarouselTop from "./Component/CarouselTop"
import NewProduct from "./Component/NewProduct"
import BestSeller from "./Component/BestSeller"
import Banner from "./Component/Banner"
import Instagram from "./Component/Instagram"
import Character from "./Component/Character"
import Footer from "./Component/Footer"
import { Helmet } from "react-helmet"
import CarouselSlide from "./Component/CarouselSlide"
// import ModalIndex from "./Component/Modal/ModalIndex"

class Index extends React.Component {
  constructor(props) {
    super(props)
  
    this.state = {
      carousel: false,
      style: false,
      newproduct: false,
      bestseller: false,
      banner: false,
      character: false,
    }
  }  

  CheckCarousel = (chk, data) => {
    this.setState({
      carousel: chk,
      datacarousel: data,
    })
  }

  CheckStyle = (chk, data) => {
    this.setState({
      style: chk,
      datastyle: data,
    })
  }

  CheckNewProduct = (chk, data) => {
    this.setState({
      newproduct: chk,
      datanewproduct: data,
    })
  }

  CheckBestSeller = (chk, data) => {
    this.setState({ 
      bestseller: chk,
      databestseller: data,
    })
  }

  CheckBanner = (chk, data) => {
    this.setState({
      banner: chk,
      databanner: data,
    })
  }

  CheckCharacter = (chk, data) => {
    this.setState({
      character: chk,
      datacharacter: data,
    })
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>บริษัท แอ๊ดด้า ฟุตแวร์ (ไทยแลนด์) จำกัด</title>
        </Helmet>
        {this.state.carousel &&
        this.state.style &&
        this.state.newproduct &&
        this.state.bestseller &&
        this.state.banner &&                
        this.state.character ? (
          <div>
            <NavNavTop />
            <NavNav />
            <CarouselTop
              ChkCarousel={this.CheckCarousel}
              Show={true}
              Data={this.state.datacarousel}
            />

            <CarouselSlide
              ChkStyle={this.CheckStyle}
              Show={true}
            />
          
            {/* <NewProduct
              ChkStyle={this.CheckStyle}
              Show={true}
              Data={this.state.datastyle}
            /> */}
           
            
            <BestSeller
              ChkNewProduct={this.CheckNewProduct}
              ChkBestSeller={this.CheckBestSeller}
              Show={true}
              DataNewProduct={this.state.datanewproduct}
              DataBestSeller={this.state.databestseller}
            />
            
            <Banner
              ChkBanner={this.CheckBanner}
              Show={true}
              Data={this.state.databanner}
            />
            <Instagram Show={true} />
            <Character
              ChkCharacter={this.CheckCharacter}
              Show={true}
              Data={this.state.datacharacter}
            />
            <Footer />
          </div>
        ) : (
          <div>
            <NavNavTop />
            <NavNav />
            <CarouselTop ChkCarousel={this.CheckCarousel} Show={false} />
            {/* <NewProduct ChkStyle={this.CheckStyle} Show={false} /> */}
            <CarouselSlide
              ChkStyle={this.CheckStyle}
              Show={false}
            />
            <BestSeller
              ChkNewProduct={this.CheckNewProduct}
              ChkBestSeller={this.CheckBestSeller}
              Show={false}
            />
            <Banner ChkBanner={this.CheckBanner} Show={false} />
            <Instagram Show={false} />
            <Character ChkCharacter={this.CheckCharacter} Show={false} />
            <div style={{ height: "800px" , display:'flex', alignItems:'center'}}>
              {/* <div class="loader">
                <div class="inner one"></div>
                <div class="inner two"></div>
                <div class="inner three"></div>
              </div> */}              
              <div class="loader"></div>
            </div>
            <Footer />
          </div>
        )}
        {/* <ModalIndex Modal={this.state.modalindex} set={this.modalIndex} /> */}
      </div>
    )
  }
}

export default Index
