import React from "react"
import { Link } from "gatsby"
import { Row, Col  } from "react-bootstrap"
import Carousel from "react-bootstrap/Carousel";
import Axios from "axios"
import { REQUEST_URL, PATH_URL } from "../../../config"

class CarouselSlide extends React.Component {
  state = {
    nameimg: "",
    index:0,
    img_path:[
      PATH_URL + "style/01.jpg",
      PATH_URL + "style/02.jpg",
      PATH_URL + "style/03.jpg",
    ]
  }

  componentDidMount = async () => {
    await Axios.post(REQUEST_URL + "/secstyle/", {}).then(response => {
      console.log(response)
      if (response.data.status) {
        this.setState({
          nameimg: response.data.data[0].nameimg,
        })
        this.props.ChkStyle(true, response.data.data[0].nameimg)
      }
    })
  }

   handleSelect = (selectedIndex, e) => {
   this.setState({index: selectedIndex})
  };

  render() {
    return (
      <div>
        <div style={{ width: "100%", marginTop: "1%" }}>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: "0px",
              marginRight: "0px",
            }}
          >
            {this.props.Show ? (
              <Col md="12" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                <Carousel activeIndex={this.state.index} onSelect={this.handleSelect}>
                  {this.state.img_path.map((item, index) => {
                    return (
                            <Carousel.Item key={index}>
                              <img
                                className="d-block w-100"
                                src={this.state.img_path[index]}
                                alt="First"
                              />
                            </Carousel.Item>
                            )
                  })}
                </Carousel>

                <Link to="/men">
                  <div className="bannernewblockmen">
                    <label className="bannertxtmen">
                      รองเท้า ผู้ชาย <span className="looksorn">﹥</span>
                    </label>
                  </div>
                  <div className="bannernewbordermen"></div>
                </Link>
                <Link to="/women">
                  <div className="bannernewblockwomen">
                    <label className="bannertxtwomen">
                      รองเท้า ผู้หญิง <span className="looksorn">﹥</span>
                    </label>
                  </div>
                  <div className="bannernewborderwomen"></div>
                </Link>
                <Link to="/kids">
                  <div className="bannernewblockkids">
                    <label className="bannertxtkids">
                      รองเท้า เด็ก <span className="looksorn">﹥</span>
                    </label>
                  </div>
                  <div className="bannernewborderkids"></div>
                </Link>
              </Col>
            ) : (
              ""
            )}
           
          </Row>
        </div>
      </div>
    )
  }
}

export default CarouselSlide
