// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-2-density-js": () => import("./../../../src/pages/2density.js" /* webpackChunkName: "component---src-pages-2-density-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-bannerproperty-js": () => import("./../../../src/pages/bannerproperty.js" /* webpackChunkName: "component---src-pages-bannerproperty-js" */),
  "component---src-pages-bestseller-js": () => import("./../../../src/pages/bestseller.js" /* webpackChunkName: "component---src-pages-bestseller-js" */),
  "component---src-pages-component-about-us-js": () => import("./../../../src/pages/Component/AboutUs.js" /* webpackChunkName: "component---src-pages-component-about-us-js" */),
  "component---src-pages-component-banner-js": () => import("./../../../src/pages/Component/Banner.js" /* webpackChunkName: "component---src-pages-component-banner-js" */),
  "component---src-pages-component-banner-property-js": () => import("./../../../src/pages/Component/BannerProperty.js" /* webpackChunkName: "component---src-pages-component-banner-property-js" */),
  "component---src-pages-component-best-seller-js": () => import("./../../../src/pages/Component/BestSeller.js" /* webpackChunkName: "component---src-pages-component-best-seller-js" */),
  "component---src-pages-component-best-seller-page-js": () => import("./../../../src/pages/Component/BestSellerPage.js" /* webpackChunkName: "component---src-pages-component-best-seller-page-js" */),
  "component---src-pages-component-carousel-slide-js": () => import("./../../../src/pages/Component/CarouselSlide.js" /* webpackChunkName: "component---src-pages-component-carousel-slide-js" */),
  "component---src-pages-component-carousel-top-js": () => import("./../../../src/pages/Component/CarouselTop.js" /* webpackChunkName: "component---src-pages-component-carousel-top-js" */),
  "component---src-pages-component-character-js": () => import("./../../../src/pages/Component/Character.js" /* webpackChunkName: "component---src-pages-component-character-js" */),
  "component---src-pages-component-contact-js": () => import("./../../../src/pages/Component/Contact.js" /* webpackChunkName: "component---src-pages-component-contact-js" */),
  "component---src-pages-component-details-js": () => import("./../../../src/pages/Component/Details.js" /* webpackChunkName: "component---src-pages-component-details-js" */),
  "component---src-pages-component-footer-js": () => import("./../../../src/pages/Component/Footer.js" /* webpackChunkName: "component---src-pages-component-footer-js" */),
  "component---src-pages-component-instagram-js": () => import("./../../../src/pages/Component/Instagram.js" /* webpackChunkName: "component---src-pages-component-instagram-js" */),
  "component---src-pages-component-kids-page-js": () => import("./../../../src/pages/Component/KidsPage.js" /* webpackChunkName: "component---src-pages-component-kids-page-js" */),
  "component---src-pages-component-men-page-js": () => import("./../../../src/pages/Component/MenPage.js" /* webpackChunkName: "component---src-pages-component-men-page-js" */),
  "component---src-pages-component-modal-modal-index-js": () => import("./../../../src/pages/Component/Modal/ModalIndex.js" /* webpackChunkName: "component---src-pages-component-modal-modal-index-js" */),
  "component---src-pages-component-navbar-js": () => import("./../../../src/pages/Component/Navbar.js" /* webpackChunkName: "component---src-pages-component-navbar-js" */),
  "component---src-pages-component-navbar-top-js": () => import("./../../../src/pages/Component/NavbarTop.js" /* webpackChunkName: "component---src-pages-component-navbar-top-js" */),
  "component---src-pages-component-new-product-js": () => import("./../../../src/pages/Component/NewProduct.js" /* webpackChunkName: "component---src-pages-component-new-product-js" */),
  "component---src-pages-component-preview-new-js": () => import("./../../../src/pages/Component/PreviewNew.js" /* webpackChunkName: "component---src-pages-component-preview-new-js" */),
  "component---src-pages-component-two-density-js": () => import("./../../../src/pages/Component/TwoDensity.js" /* webpackChunkName: "component---src-pages-component-two-density-js" */),
  "component---src-pages-component-women-page-js": () => import("./../../../src/pages/Component/WomenPage.js" /* webpackChunkName: "component---src-pages-component-women-page-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-details-js": () => import("./../../../src/pages/details.js" /* webpackChunkName: "component---src-pages-details-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kids-js": () => import("./../../../src/pages/kids.js" /* webpackChunkName: "component---src-pages-kids-js" */),
  "component---src-pages-men-js": () => import("./../../../src/pages/men.js" /* webpackChunkName: "component---src-pages-men-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-previewnew-js": () => import("./../../../src/pages/previewnew.js" /* webpackChunkName: "component---src-pages-previewnew-js" */),
  "component---src-pages-view-17-adda-2-density-js": () => import("./../../../src/pages/view/17/ADDA-2-Density.js" /* webpackChunkName: "component---src-pages-view-17-adda-2-density-js" */),
  "component---src-pages-women-js": () => import("./../../../src/pages/women.js" /* webpackChunkName: "component---src-pages-women-js" */)
}

