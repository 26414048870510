import React from "react"
import { Link } from "gatsby"
// import { Row, Col } from "react-bootstrap"
// import axios from "axios"
// import { REQUEST_URL, PATH_URL } from "../../../config"
// import PicLife from "../../../img/banner/banner1920x900.mp4"
import ReactPlayer from "react-player"
import Axios from "axios"
import { REQUEST_URL, PATH_URL } from "../../../config"

class Banner extends React.Component {
  state = {
    banner: "",
  }

  componentDidMount = () => {
    Axios.post(REQUEST_URL + "/fthbanner/", {}).then(response => {
      if (response.data.status) {
        this.setState({
          banner: response.data.data[0].nameimg,
        })
        this.props.ChkBanner(true)
      }
    })
  }

  render() {
    return (
      <div style={{ overflowX: "hidden" }}>
        {this.props.Show ? (
          <Link to="/view/17/ADDA-2-Density">
            <div
              style={{
                width: "100%",
                cursor: "pointer",
              }}
            >
                <img
                  src={PATH_URL + "banner/" + this.state.banner}
                  className="NewProduct"
                />
              {/* <ReactPlayer
                url={PATH_URL + "banner/" + this.state.banner}
                autoPlay
                playing={true}
                loop={true}
                width="100%"
                height="100%"
                volume={0}
                muted
              /> */}
              {/* <ReactPlayer
              url={PATH_URL + "banner/" + this.state.banner}
              autoPlay
              playing={true}
              loop={true}
              width="100%"
              height="100%"
              volume={0}
              muted
            /> */}
              {/* <Row style={{ zIndex: "1" }}> */}
              {/* <Col md="12"> */}
              {/* <img src={PicLife} width="100%" /> */}
              {/* </Col> */}
              {/* </Row> */}
            </div>
          </Link>
        ) : (
          ""
        )}
      </div>
    )
  }
}

export default Banner
