import React from "react"
import { Link } from "gatsby"
import {
  FaInstagram,
  FaYoutube,
  FaFacebook,
  FaShoppingCart,
} from "react-icons/fa"

class Footer extends React.Component {
  render() {
    return (
      <div style={{}}>
        <footer
          className="bg3 p-t-75 p-b-32"
          style={{ backgroundColor: "#f26f21" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-lg-3 p-b-50">
                <h4 className="stext-301 cl0 p-b-30">Main Menu</h4>
                <ul>
                  <li className="p-b-10">
                    <Link to="/men">
                      <label
                        style={{ cursor: "pointer" }}
                        className="stext-107 cl7 hov-cl1 trans-04"
                      >
                        Men
                      </label>
                    </Link>
                  </li>
                  <li className="p-b-10">
                    <Link to="/women">
                      <label
                        style={{ cursor: "pointer" }}
                        className="stext-107 cl7 hov-cl1 trans-04"
                      >
                        Women
                      </label>
                    </Link>
                  </li>
                  <li className="p-b-10">
                    <Link to="/kids">
                      <label
                        style={{ cursor: "pointer" }}
                        className="stext-107 cl7 hov-cl1 trans-04"
                      >
                        Kids
                      </label>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-lg-3 p-b-50">
                <h4 className="stext-301 cl0 p-b-30">Contact or Help</h4>
                <ul>
                  <li className="p-b-10">
                    <label className="stext-107 cl7 hov-cl1 trans-04">
                      Tel  0 2416 0026
                    </label>
                  </li>
                  <li className="p-b-10">
                    <label className="stext-107 cl7 hov-cl1 trans-04">
                      Fax  0 2899 8488
                    </label>
                  </li>
                  <li className="p-b-10">
                    <label className="stext-107 cl7 hov-cl1 trans-04">
                      E-mail  marketing@adda.co.th ,
                    </label>
                  </li>
                  <li className="p-b-10">
                    <label className="stext-107 cl7 hov-cl1 trans-04">
                                     exportadda@adda.co.th
                    </label>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-lg-3 p-b-50">
                <h4 className="stext-301 cl0 p-b-30">Location</h4>
                <p className="stext-107 cl7 size-201">
                  ADDA FOOTWEAR (THAILAND) CO.,LTD.       45 Soi Phraya Monthat
                  Ratchasi Pichit, Klong Bangbon, Bangbon, Bangkok 10150,
                  Thailand
                </p>
              </div>
              <div className="col-sm-6 col-lg-3 p-b-50">
                <h4 className="stext-301 cl0 p-b-30">Social Media</h4>
                <div className="wrap-input1 w-full p-b-4">
                  <p
                    className="stext-107 cl7 size-201 hov-cl1"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open("https://www.facebook.com/Addathailand")
                    }
                  >
                    <a className="fs-18 cl7 hov-cl1 trans-04 m-r-16">
                      <FaFacebook />
                    </a>
                    FACEBOOK : (ADDA Thailand)
                  </p>
                  <p
                    className="stext-107 cl7 size-201 hov-cl1"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open("https://www.instagram.com/addathailand/")
                    }
                  >
                    <a className="fs-18 cl7 hov-cl1 trans-04 m-r-16">
                      <FaInstagram />
                    </a>
                    INSTAGRAM : (addathailand)
                  </p>
                  <p
                    className="stext-107 cl7 size-201 hov-cl1"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open("https://www.youtube.com/user/ADDATHAILAND")
                    }
                  >
                    <a className="fs-18 cl7 hov-cl1 trans-04 m-r-16">
                      <FaYoutube />
                    </a>
                    YOUTUBE : (ADDATHAILAND)
                  </p>
                  <p
                    className="stext-107 cl7 size-201 hov-cl1"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open("https://shopee.co.th/adda_online_shop")
                    }
                  >
                    <a className="fs-18 cl7 hov-cl1 trans-04 m-r-16">
                      <FaShoppingCart />
                    </a>
                    SHOPEE : (ADDA Official Store)
                  </p>
                  <p
                    className="stext-107 cl7 size-201 hov-cl1"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://www.lazada.co.th/shop/adda-online-shop-official"
                      )
                    }
                  >
                    <a className="fs-18 cl7 hov-cl1 trans-04 m-r-16">
                      <FaShoppingCart />
                    </a>
                    LAZADA : (ADDA)
                  </p>
                  {/* <iframe
                    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FAddathailand%2F&tabs&width=340&height=214&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                    width={340}
                    height={214}
                    style={{ border: "none", overflow: "hidden" }}
                    scrolling="no"
                    frameBorder={0}
                    allowTransparency="true"
                    allow="encrypted-media"
                  /> */}
                </div>
              </div>
            </div>
            <p className="stext-107 cl6 txt-center">
              Copyright © 2020 ADDA Footwear (Thailand) Co., Ltd. All rights
              reserved.
              <i className="fa fa-heart-o" aria-hidden="true" /> by{" "}
            </p>
          </div>
        </footer>
      </div>
    )
  }
}

export default Footer
