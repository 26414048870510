import React from "react"
// import { Link } from "gatsby"
import { Row, Col } from "react-bootstrap"
// import axios from "axios"
// import { REQUEST_URL, PATH_URL } from "../../../config"
import { FaInstagram } from "react-icons/fa"
import Helmet from "react-helmet"

class BestSeller extends React.Component {
  render() {
    return (
      <div
        style={{
          overflowX: "hidden",
        }}
      >
        <Helmet>
          {/* <script src="https://apps.elfsight.com/p/platform.js" defer></script> */}
          {/* <script src="https://www.powr.io/powr.js?platform=iframe"></script> */}
          <script src="https://cdn.lightwidget.com/widgets/lightwidget.js"></script>
        </Helmet>
        {this.props.Show ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Row>
              <Col md="12">
                <label
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "40px",
                  }}
                >
                  <FaInstagram /> อินสตาแกรม
                </label>
              </Col>
            </Row>
          </div>
        ) : (
          ""
        )}
        {this.props.Show ? (
          <Row>
            <Col md="12">
              <div>
                <iframe
                  src="//lightwidget.com/widgets/a9cc04714d3a5034968d558d1b35f8ea.html"
                  scrolling="no"
                  allowtransparency="true"
                  class="lightwidget-widget"
                ></iframe>
              </div>
              {/* <div class="powr-instagram-feed" id="a18e62c3_1579247118"></div> */}
              {/* <div className="elfsight-app-aeca943a-86f7-4667-b478-0b6baf5099b4"></div> */}
              {/* <Slider {...settings} style={{ zIndex: 0 }}> */}
              {/* <div className="elfsight-app-aeca943a-86f7-4667-b478-0b6baf5099b4"></div> */}
              {/* <img src={A} width="100%" style={{ marginBottom: "0px" }} />
              <img src={B} width="100%" style={{ marginBottom: "0px" }} />
              <img src={C} width="100%" style={{ marginBottom: "0px" }} />
              <img src={D} width="100%" style={{ marginBottom: "0px" }} /> */}
              {/* </Slider> */}
              {/* <div className="blockbannerfree"></div> */}
            </Col>
          </Row>
        ) : (
          ""
        )}
      </div>
    )
  }
}

export default BestSeller
