import React from "react"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import { FaYoutube, FaFacebook } from "react-icons/fa"

class NavNavTop extends React.Component {
  render() {
    return (
      <div style={{ overflowX: "hidden" }}>
        <Row
          style={{
            backgroundImage:
              "linear-gradient(black,black,black,black,black,grey)",
          }}
        >
          <Col md="1"></Col>
          <Col
            md="10"
            style={{
              textAlign: "right",
              alignItems: "center",
              fontFamily: "PSL-Kanda",
            }}
          >
            <span style={{ color: "white", fontSize: "14px" }}>ADDA.CO.TH</span>
            <span style={{ color: "white", fontSize: "14px" }}>   |   </span>
            <span
              className="shopeetop"
              onClick={() =>
                window.open("https://shopee.co.th/adda_online_shop")
              }
            >
              SHOPEE
            </span>
            <span style={{ color: "white", fontSize: "14px" }}>   |  </span>
            <span
              className="lazadatop"
              onClick={() =>
                window.open(
                  "https://www.lazada.co.th/shop/adda-online-shop-official"
                )
              }
            >
              LAZADA
            </span>
            <span style={{ color: "white", fontSize: "14px" }}>   |  </span>
            <span
              className="fbtop"
              onClick={() =>
                window.open("https://www.facebook.com/Addathailand")
              }
            >
              <FaFacebook />
            </span>
            <span style={{ color: "white", fontSize: "14px" }}>  </span>
            <span
              className="yttop"
              onClick={() =>
                window.open("https://www.youtube.com/user/ADDATHAILAND")
              }
            >
              <FaYoutube />
            </span>
            <span style={{ color: "white", fontSize: "14px" }}>  </span>
            <span
              className="yttop"
              onClick={() => (window.location.href = "https://adda.co.th/en")}
            >
              EN
            </span>
          </Col>
          <Col md="1"></Col>
        </Row>
       
      </div>
    )
  }
}

export default NavNavTop
