// export const REQUEST_URL = "http://10.32.1.157:7008";
// export const REQUEST_URL = "http://10.32.0.84:7008";
// export const REQUEST_URL = "http://10.32.0.84/webaddanew";
// export const REQUEST_URL = "http://111.223.38.24:5000"
// export const REQUEST_URL = "https://1faf1ade27eb.ngrok.io"
// export const REQUEST_URL = "https://8303489318f0.in.ngrok.io"

// export const PATH_URL = "http://111.223.38.24/webservice_adda/";
// export const PATH_URL = "http://localhost/serviceadda/"

// export const PATH_URL = "https://f8e4d859f41e.ngrok.io/webservice_adda/";

export const REQUEST_URL = "https://adda.co.th/api/v1/"

export const PATH_URL = "https://adda.co.th/img/";
