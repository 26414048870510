import React from "react"
import { Link } from "gatsby"
import { Row, Col } from "react-bootstrap"
import Carousel from "react-bootstrap/Carousel";
// import loading from "../../../img/newnbest/loadingthird.gif"
// import bestseller from "../../../img/newnbest/Best-Seller.jpg"
// import Slider from "react-slick"
// import { FaArrowRight } from "react-icons/fa"
import Axios from "axios"
import { REQUEST_URL, PATH_URL } from "../../../config"

class BestSeller extends React.Component {
  state = {
    newproduct: "",
    bestseller: "",
    index:0,
    img_path:[
      PATH_URL + "newproduct/1.jpg",
      PATH_URL + "newproduct/2.jpg", 
      PATH_URL + "newproduct/3.jpg",
      PATH_URL + "newproduct/4.jpg",
      PATH_URL + "newproduct/5.jpg",
      PATH_URL + "newproduct/6.jpg",
      PATH_URL + "newproduct/7.jpg",
      PATH_URL + "newproduct/8.jpg",
      PATH_URL + "newproduct/9.jpg",
      PATH_URL + "newproduct/10.jpg",
      PATH_URL + "newproduct/11.jpg",
      PATH_URL + "newproduct/12.jpg",
      PATH_URL + "newproduct/13.jpg",
      PATH_URL + "newproduct/14.jpg",
      PATH_URL + "newproduct/15.jpg",
    ]
  }

  componentDidMount = async () => {
    await Axios.post(REQUEST_URL + "/thdnewproduct/", {}).then(response => {
      if (response.data.status) {
        this.setState({
          newproduct: response.data.data[0].nameimg,
        })
        this.props.ChkNewProduct(true, response.data.data[0].nameimg)
      }
    })
    await Axios.post(REQUEST_URL + "/thdbestseller/", {}).then(response => {
      if (response.data.status) {
        this.setState({
          bestseller: response.data.data[0].nameimg,
        })
        this.props.ChkBestSeller(true, response.data.data[0].nameimg)
      }
    })
  }

  handleSelect = (selectedIndex, e) => {
    this.setState({index: selectedIndex})
   };

  render() {
    return (
      <div style={{ overflowX: "hidden" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "1%",
          }}
          className="container-fluid"
        >
          <Row>
            {this.props.Show ? (
              <Col md="6" xs="6" sm="6">
                <Link to="/previewnew">
                  <div className="newproductz">
                    {/* <img
                      src={PATH_URL + "newproduct/" + this.props.DataNewProduct}
                      width="100%"
                      className="picnewproduct"
                    /> */}
                    <Carousel activeIndex={this.state.index} onSelect={this.handleSelect}  indicators={false}>
                      {this.state.img_path.map((item, index) => {
                        return (
                                <Carousel.Item key={index}
                                >
                                  <img
                                    className="d-block w-100"
                                    src={this.state.img_path[index]}
                                    alt="First"
                                  />
                                </Carousel.Item>
                                )
                      })}
                    </Carousel>
                    {/* <div className="blocknewproduct"></div> */}
                    {/* <div className="buttonnewproduct"> */}
                    {/* <div className="textnewproduct">ดูสินค้าล่วงหน้า</div> */}
                    {/* </div> */}
                  </div>
                </Link>
              </Col>
            ) : (
              ""
            )}
            {this.props.Show ? (
              <Col md="6" xs="6" sm="6">
                <Link to="/bestseller">
                  <div className="bestsellerz">
                    <img
                      src={PATH_URL + "bestseller/" + this.props.DataBestSeller}
                      width="100%"
                      className="picbestseller"
                    />
                    <div className="blockbestseller"></div>
                    {/* <div className="buttonbestseller"> */}
                    {/* <div className="textbestseller">ชื้อสินค้า</div> */}
                    {/* </div> */}
                  </div>
                </Link>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </div>
      </div>
    )
  }
}

export default BestSeller

//<Row>
//<Col md="6" xs="6" sm="6">
//  <div className="newshoesblock">
//   <div className="newshoesborder">
//     <label className="newshoestxt">
//ดูสินค้าล่วงหน้า
//      {/* <FaArrowRight className="arrownewshoes" /> */}
//   </label>
//</div>
// </div>
// <div className="newshoesblock1"></div>
// <img src={newproduct} width="100%" />
//</Col>
//<Col md="6" xs="6" sm="6">
// <div className="bestsellerblock">
//   <div className="bestsellerborder">
//    <label className="bestsellertxt">
//      ช้อปเลย
//      {/* <FaArrowRight className="arrowbestseller" /> */}
//    </label>
//  </div>
//</div>
//  <div className="bestsellerblock1"></div>
//  <img src={bestseller} width="100%" />
//</Col>
//</Row>
